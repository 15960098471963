import "../assets/styles/HiBubble.css";

export default function HiBubble() {
  return (
    <div className="hi-bubble-container">
      Hi! It's me
      <div className="arrow-down"></div>
    </div>
  );
}
