// style imports
import "../assets/styles/Skills.css";

// react-icon imports
import { SiJavascript } from "react-icons/si";
import { SiHtml5 } from "react-icons/si";
import { SiCss3 } from "react-icons/si";
import { SiReact } from "react-icons/si";
import { SiGit } from "react-icons/si";
import { SiJest } from "react-icons/si";
import { SiWebpack } from "react-icons/si";


// theme-ui imports
/** @jsxImportSource theme-ui */

export default function Skills() {
  return (
    <div className="skills-component">
      <div className="skills-content">
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>Javascript</span>
          <SiJavascript sx={{ color: "skillIcon" }} className="icon javascript" />
        </div>
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>C++</span>
          <svg
            sx={{ color: "skillIcon" }}
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            role="img"
            viewBox="0 0 24 24"
            className="icon cpp"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M20.66 7a1.51 1.51 0 0 0-.55-.57l-7.34-4.24a1.67 1.67 0 0 0-1.54 0L3.89 6.43a1.68 1.68 0 0 0-.77 1.33v8.48a1.57 1.57 0 0 0 .22.76 1.51 1.51 0 0 0 .55.57l7.34 4.24a1.67 1.67 0 0 0 1.54 0l7.34-4.24a1.51 1.51 0 0 0 .55-.57 1.57 1.57 0 0 0 .22-.76V7.76a1.57 1.57 0 0 0-.22-.76zM12 17.92A5.92 5.92 0 1 1 17.13 9L16 9.71l-.36.2-1 .61A3 3 0 0 0 9 12a2.88 2.88 0 0 0 .4 1.48 3 3 0 0 0 5.13 0l2.6 1.52A5.94 5.94 0 0 1 12 17.92zm5.92-5.59h-.66V13h-.65v-.66H16v-.66h.66V11h.65v.66h.66zm2.47 0h-.66V13h-.66v-.66h-.65v-.66h.65V11h.66v.66h.66z"
            />
          </svg>
        </div>
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>HTML</span>

          <SiHtml5 sx={{ color: "skillIcon" }} className="icon" />
        </div>
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>CSS</span>
          <SiCss3 sx={{ color: "skillIcon" }} className="icon" />
        </div>
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>ReactJS</span>
          <SiReact sx={{ color: "skillIcon" }} className="icon" />
        </div>
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>git</span>
          <SiGit sx={{ color: "skillIcon" }} className="icon git" />
        </div>
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>Jest</span>
          <SiJest sx={{ color: "skillIcon" }} className="icon" />
        </div>
        <div sx={{ backgroundColor: "skillTab" }} className="skill-entry">
          <span sx={{ color: "skillSpan" }}>Webpack</span>
          <SiWebpack sx={{ color: "skillIcon" }} className="icon" />
        </div>
      </div>
    </div>
  );
}
