// style imports
import "../assets/styles/ProjectEntry.css";
// image imports
import screenshot from "../assets/images/screenshot.png";
import { AiFillGithub } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { FaFileDownload } from "react-icons/fa";

// theme-ui imports
/** @jsxImportSource theme-ui */

export default function ProjectEntry(props) {
  return (
    <div className="project-entry-container">
      <img src={props.img ? props.img : "#"} alt="" />
      <div className="info-container">
        <div className="title">{props.name ? props.name : ""}</div>
        <div sx={{ color: "subText" }} className="description">
          {props.description ? props.description : ""}
        </div>
        <div className="button-container">
          <a
            href={props.sourceCodeUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              sx={{
                backgroundColor: "projectButtonBackground",
                color: "projectButtonText",
              }}
              className="code"
            >
              <span sc={{}}>Source Code</span>
              <AiFillGithub
                sx={{ color: "projectButtonIcon" }}
                className="icon"
              />
              <div className="icon"></div>
            </button>
          </a>
          {props.introductionButton !== true && (
            <a
              href={props.liveDemoUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                sx={{
                  backgroundColor: "projectButtonBackground",
                  color: "projectButtonText",
                }}
                className="live-demo"
              >
                <span>Live Demo</span>
                <AiFillEye
                  sx={{ color: "projectButtonIcon" }}
                  className="icon"
                />
                <div className="icon"></div>
              </button>
            </a>
          )}
          {props.introductionButton === true && (
            <a
              href={props.introductionUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                sx={{
                  backgroundColor: "projectButtonBackground",
                  color: "projectButtonText",
                }}
                className="live-demo"
              >
                <span>Introduction</span>
                <FaFileDownload
                  sx={{ color: "projectButtonIcon" }}
                  className="icon"
                />
                <div className="icon"></div>
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
