//css import
import "../assets/styles/AboutMe.css";
import Skills from "./Skills";
import Title from "./Title";

// theme-ui imports
/** @jsxImportSource theme-ui */

export default function AboutMe() {
  return (
    <div sx={{ backgroundColor: "aboutMe" }} className="about-me-container">
      <div className="about-me-content">
        <Title title="ABOUT ME" className="about-me" />

        <div sx={{ color: "subText" }} className="summary-container">
          Hi, I'm Baha Homidov, a software developer pursuing a
          degree as an international student in China. Aside from my passion for
          programming, which we'll get into further in the portfolio. I'm also
          an avid natural language learner—a speaker of four languages, to be
          precise. Outside of learning time, I like doing sports, tinkering with
          my motorcycle and 3D printer, discovering great books on my Kindle,
          and traveling to new places.
        </div>
        <div className="skills-container">
          <Title title="MY SKILLS" className="skills" />
          <Skills />
        </div>
      </div>
    </div>
  );
}
