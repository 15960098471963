// asset imports
import "../assets/styles/TopBar.css";
import logoLight from "../assets/images/logo_web_light.png";
import logoDark from "../assets/images/logo_web_dark.png";
// icons from react-icons
import { BiMoon } from "react-icons/bi";
import { MdFlipToFront, MdWbSunny } from "react-icons/md";
// react imports
import { useEffect, useRef, useState } from "react";
// hooks from react-spring for animations
import { useTransition, animated, flip } from "@react-spring/web";
// component imports
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu"; 

// theme-ui imports
/** @jsxImportSource theme-ui */

export default function TopBar(props) {
  const [darkMode, setDarkMode] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [mobileMenuProp, setMobileMenuProp] = useState(false);
  const menuButtonRef = useRef(null);
  // react-spring transition hook for dark-mode toggle
  const darkModeTransition = useTransition(darkMode, {
    config: { duration: 120 },
    from: { y: -30, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: 30, opacity: 0 },
  });

  // react-spring transition hook for mobile-menu
  const mobileMenuTransition = useTransition(showMobileMenu, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 100, // duration for the whole animation form start to end
    },
  });

  function toggleDarkMode() {
    setDarkMode(!darkMode);
    props.toggleDarkMode();
  }

  function toggleMobileMenu(e) {
    if (showMobileMenu) {
      setMobileMenuProp(false);
      setShowMobileMenu(false);
    } else {
      setMobileMenuProp(true);
      setShowMobileMenu(true);
    }
  }

  function hideMenuProp() {
    setMobileMenuProp(false);
    setShowMobileMenu(false);
    menuButtonRef.current.checked = false;
  }

  window.hideMenuProp = hideMenuProp;

  return (
    <div sx={{ backgroundColor: "topBar" }} className="top-bar-container">
      <div className="top-bar-content">
        {/* <MobileMenu showMobileMenu={showMobileMenu} */}
        {mobileMenuTransition(
          (styles, showMobileMenu) =>
            showMobileMenu && (
              <animated.div
                style={{
                  zIndex: 999,

                  ...styles,
                }}
              >
                {<MobileMenu hideMenuProp={hideMenuProp} />}
              </animated.div>
            )
        )}

        {/* <div className="title">BAHA</div> */}
        <img
          src={props.colorMode === "light" ? logoLight : logoDark}
          alt=""
          className="title"
        />
        <div onClick={toggleDarkMode} className="dark-light-icon">
          {darkModeTransition((style, item) =>
            item ? (
              <animated.div style={style}>
                <BiMoon className="dark-icon" />
              </animated.div>
            ) : (
              <animated.div style={style}>
                <MdWbSunny className="light-icon" />
              </animated.div>
            )
          )}
        </div>

        <DesktopMenu />

        <div className="menu-icon">
          <input
            ref={menuButtonRef}
            onClick={toggleMobileMenu}
            type="checkbox"
            id="menu_checkbox"
          />
          <label htmlFor="menu_checkbox">
            <div sx={{ backgroundColor: "text" }}></div>
            <div sx={{ backgroundColor: "text" }}></div>
            <div sx={{ backgroundColor: "text" }}></div>
          </label>
        </div>
      </div>
    </div>
  );
}
