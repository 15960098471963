// css imports
import "../assets/styles/Contact.css";
// component imports
import Title from "./Title";
// react-icon imports
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { VscTwitter } from "react-icons/vsc";

// React imports
import { useState, useRef } from "react";

// Email.js imports
import emailjs from "@emailjs/browser";

// react-spring imports
import { animated, config, useTransition } from "@react-spring/web";

// theme-ui imports
/** @jsxImportSource theme-ui */

export default function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const form = useRef();

  const successTranstion = useTransition(showSuccess, {
    from: { y: -35, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -35, opacity: 0 },
    config: config.wobbly,
  });
  const errorTransition = useTransition(showError, {
    from: { y: -35, opacity: 0 },
    enter: { y: 0, opacity: 1 },
    leave: { y: -35, opacity: 0 },
    config: config.wobbly,
  });

  function confirmError() {
    setShowSpinner(false);
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 2000);

    setTimeout(() => {
      setShowSpinner(true);
      setShowLoading(false);
    }, 3000);
  }

  function confirmSuccess() {
    setShowSpinner(false);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 2000);

    setTimeout(() => {
      setShowSpinner(true);
      setShowLoading(false);
    }, 3000);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    console.log(form.current);
    setShowLoading(true);

    emailjs
      .sendForm(
        "service_wmv3q84",
        "template_nzxf7qp",
        form.current,
        "KsSP9TRzQt1lPyi-k"
      )
      .then(
        (result) => {
          confirmSuccess();
          e.target.reset(); // reset the form after completion
        },
        (error) => {
          confirmError();
          console.log(error);
        }
      );
  };

  function handleName(event) {
    setName(event.target.value);
  }
  function handleEmail(event) {
    setEmail(event.target.value);
  }
  function handleMessage(event) {
    setMessage(event.target.value);
  }

  return (
    <div className="contact-container">
      <div className="contact-content">
        <Title title="CONTACT ME" className="contact-title" />

        <div className="social-container">
          <a
            href="https://www.linkedin.com/in/baha-homidov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              sx={{ backgroundColor: "projectButtonBackground" }}
              title="LinkedIn"
              className="entry"
            >
              <AiFillLinkedin
                sx={{ color: "projectButtonText" }}
                className="icon linked-in"
              />
            </div>
          </a>
          <a
            href="https://github.com/baha-homidov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              sx={{ backgroundColor: "projectButtonBackground" }}
              title="GitHub"
              className="entry"
            >
              <AiFillGithub
                sx={{ color: "projectButtonText" }}
                className="icon"
              />
            </div>
          </a>
          <a
            href="https://twitter.com/baha_homidov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div
              sx={{ backgroundColor: "projectButtonBackground" }}
              title="Twitter"
              className="entry"
            >
              <VscTwitter
                sx={{ color: "projectButtonText" }}
                className="icon twitter"
              />
            </div>
          </a>
          <a href="mailto:bahahomidov@outlook.com">
            <div
              sx={{ backgroundColor: "projectButtonBackground" }}
              title="Email"
              className="entry"
            >
              <AiOutlineMail
                sx={{ color: "projectButtonText" }}
                className="icon"
              />
            </div>
          </a>
        </div>

        <div className="divider-container">
          <div
            sx={{ backgroundColor: "scrollDownText" }}
            className="line"
          ></div>
          <div sx={{ color: "scrollDownText" }} className="or">
            or submit this form
          </div>
          <div
            sx={{ backgroundColor: "scrollDownText" }}
            className="line"
          ></div>
        </div>

        <div
          sx={{ backgroundColor: "contactFormBackground" }}
          className="form-container"
        >
          <form ref={form} className="contanct-form" onSubmit={sendEmail}>
            {showLoading && (
              <div className="loading-container">
                {errorTransition((style, item) =>
                  item ? (
                    <animated.div style={style}>
                      <div
                        sx={{
                          backgroundColor: "contactConfirmationBackground",
                        }}
                        className="success"
                      >
                        Error sending the message
                      </div>
                    </animated.div>
                  ) : (
                    ""
                  )
                )}
                {successTranstion((style, item) =>
                  item ? (
                    <animated.div style={style}>
                      <div
                        sx={{
                          backgroundColor: "contactConfirmationBackground",
                        }}
                        className="success"
                      >
                        Your message has been sent
                      </div>
                    </animated.div>
                  ) : (
                    ""
                  )
                )}
                {showSpinner && (
                  <div className="loading" style={{ zIndex: 999 }}>
                    <section className="container">
                      <div>
                        <div>
                          <span className="one h6"></span>
                          <span className="two h3"></span>
                        </div>
                      </div>

                      <div>
                        <div>
                          <span className="one h1"></span>
                          <span className="two h4"></span>
                        </div>
                      </div>

                      <div>
                        <div>
                          <span className="one h5"></span>
                          <span className="two h2"></span>
                        </div>
                      </div>
                    </section>
                  </div>
                )}
              </div>
            )}
            <div className="input-container ">
              <input
                sx={{
                  borderColor: "contactFormInputBorder",
                  borderWidth: "1px",
                  backgroundColor: "contactFormInputBackground",
                  color: "text",
                  "&:focus + .label": {
                    color: "contactFormLabel",
                  },
                  "&:not(:placeholder-shown) + .label": {
                    color: "contactFormLabel",
                  },
                }}
                type="text"
                className="name"
                name="user_name"
                value={name}
                onChange={handleName}
                required
                placeholder=" "
              />
              <span className="label">Name</span>
            </div>
            <div className="input-container">
              <input
                sx={{
                  borderColor: "contactFormInputBorder",
                  borderWidth: "1px",
                  backgroundColor: "contactFormInputBackground",
                  color: "text",
                  "&:focus + .label": {
                    color: "contactFormLabel",
                  },
                  "&:not(:placeholder-shown) + .label": {
                    color: "contactFormLabel",
                  },
                }}
                type="text"
                className="name"
                name="user_email"
                value={email}
                onChange={handleEmail}
                required
                placeholder=" "
              />
              <span className="label">Email</span>
            </div>
            <div className="message-container">
              <textarea
                sx={{
                  borderColor: "contactFormInputBorder",
                  borderWidth: "1px",
                  backgroundColor: "contactFormInputBackground",
                  color: "text",
                  "&:focus + .label": {
                    color: "contactFormLabel",
                  },
                  "&:not(:placeholder-shown) + .label": {
                    color: "contactFormLabel",
                  },
                }}
                onChange={handleMessage}
                value={message}
                className="message"
                cols="30"
                rows="10"
                maxLength="160"
                placeholder=" "
                name="message"
              ></textarea>
              <span className="label">Message</span>
            </div>
            <button
              sx={{
                color: "projectButtonText",
                backgroundColor: "projectButtonBackground",
              }}
              type="submit"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
