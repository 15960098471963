// react-spring imports
import { useSpring, animated, useTransition, config } from "@react-spring/web";
// react imports
import { useEffect, useState } from "react";

// style imports
import "../assets/styles/MobileMenu.css";
// theme-ui imports
/** @jsxImportSource theme-ui */

export default function MobileMenu(props) {
  return (
    <div className="mobile-menu-container">
      <a onClick={props.hideMenuProp} href="#about-me">
        <button
          sx={{
            color: "menuText",
            backgroundColor: "menuBackground",
            borderTop: "menuLine",
          }}
          className="menu-entry"
        >
          ABOUT ME
        </button>
      </a>
      <a onClick={props.hideMenuProp} href="#projects">
        <button
          sx={{
            color: "menuText",
            backgroundColor: "menuBackground",
            borderTop: "menuLine",
          }}
          className="menu-entry"
        >
          PROJECTS
        </button>
      </a>
      <a onClick={props.hideMenuProp} href="#contact">
        <button
          sx={{
            color: "menuText",
            backgroundColor: "menuBackground",
            borderTop: "menuLine",
          }}
          className="menu-entry right"
        >
          CONTACT ME
        </button>
      </a>
    </div>
  );
}
