// style imports
import "../assets/styles/Title.css";
 

// theme-ui imports
/** @jsxImportSource theme-ui */


export default function Title(props) {
  return (
    <div className={`title-container ${props.className}`}>
      <h2 sx={{color: "text"}} className="title">{props.title}</h2>
      <div sx={{backgroundColor: "orange"}} className="horizontal-bar"></div>
    </div>
  );
}
