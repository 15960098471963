// style import
import "../assets/styles/LandingScreen.css";
// asset imports
import facePhoto from "../assets/images/face.png";
// component imports
import HiBubble from "./HiBubble";
// spring-react imports
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGithub } from "react-icons/ai";
import { VscTwitter } from "react-icons/vsc";

// theme-ui imports
/** @jsxImportSource theme-ui */

// typewriter-effect import for typing example
import TypewriterComponent from "typewriter-effect";

import {
  useSpring,
  animated,
  config,
  useSprings,
  useSpringRef,
  useChain,
} from "@react-spring/web";

export default function LandingScreen() {
  const popInAnimationRef = useSpringRef();
  const popInAnimation = useSpring({
    to: { transform: `scale(1)` },
    from: { transform: `scale(0)` },
    config: config.wobbly,
    ref: popInAnimationRef,
  });

  const firstNameAnimtationRef = useSpringRef();
  const firstNameAnimation = useSpring({
    to: { x: 0, opacity: 1 },
    from: { x: -100, opacity: 0 },

    ref: firstNameAnimtationRef,
  });
  const lastNameAnimationRef = useSpringRef();
  const lastNameAnimation = useSpring({
    to: { x: 0, opacity: 1 },
    from: { x: 100, opacity: 0 },

    ref: lastNameAnimationRef,
  });

  const titleAnimtionRef = useSpringRef();
  const titleAnimtion = useSpring({
    to: { y: 0, opacity: 1 },
    from: { y: 40, opacity: 0 },
    config: config.stiff,
    ref: titleAnimtionRef,
  });

  useChain(
    [
      popInAnimationRef,
      firstNameAnimtationRef,
      lastNameAnimationRef,
      titleAnimtionRef,
    ],
    [1, 2, 2.8, 3.5]
  );

  return (
    <div className="landing-screen-container">
      <div className="landing-screen-content">
        <div
          sx={{ backgroundColor: "socialBackground" }}
          className="social-link-container"
        >
          <a
            href="https://www.linkedin.com/in/baha-homidov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="social">
              <AiFillLinkedin sx={{ color: "text" }} className="icon" />
            </button>
          </a>
          <a
            href="https://github.com/baha-homidov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="social">
              <AiFillGithub sx={{ color: "text" }} className="icon" />
            </button>
          </a>
          <a
            href="https://twitter.com/baha_homidov"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="social">
              <VscTwitter sx={{ color: "text" }} className="icon" />
            </button>
          </a>
        </div>
        <div className="text-wrapper">
          <div className="inner-text-wrapper">
            {
              <animated.div
                className="hi-bubble-wrapper"
                style={popInAnimation}
              >
                <HiBubble />
              </animated.div>
            }
            <div className="name-container">
              {
                <div className="name">
                  <animated.div
                    className="first-name"
                    style={firstNameAnimation}
                    sx={{ color: "orange" }}
                  >
                    Baha
                  </animated.div>

                  <animated.div style={lastNameAnimation}>Homidov</animated.div>
                </div>
              }
            </div>
            <div className="title-container">
              {
                <animated.div sx={{ color: "s" }} style={titleAnimtion}>
                  <TypewriterComponent
                    options={{
                      strings: [
                        "Software Developer",
                        "Web Developer",
                        "Frontend Developer",
                      ],
                      autoStart: true,
                      loop: true,
                      wrapperClassName: "title",

                      pauseFor: 3000,
                    }}
                  />
                  {/* Software Developer */}
                </animated.div>
              }
            </div>
          </div>
        </div>
        <div className="photo-container">
          <img src={facePhoto} alt="face" />
        </div>

        <div className="scroll-down-icon">
          {/*  eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a sx={{ color: "scrollDownText" }} href="#about-me">
            <div
              sx={{ borderColor: "scrollDownText", borderWidth: "2px" }}
            ></div>
            Scroll
          </a>
        </div>
      </div>
    </div>
  );
}
