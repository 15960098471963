const theme = {
  initialColorMode: "light",
  colors: {
    text: "black",
    subText: "black",
    menuText: "#434446",
    background: "white",
    primary: "blue",
    topBar: "rgba(255, 255, 255, 0.983)",
    menuBackground: "#ffffffca",
    menuLine: "1px solid #eeeeee",
    socialBackground: "white",
    scrollDownIcon: "2px solid #58595bc8",
    scrollDownText: "#58595bc8",
    orange: "#de3425",
    aboutMe: "#fafafa",
    skillTab: "#e7e7e7",
    skillSpan: "#000000",
    skillIcon: "#2c2c2c60",
    projectButtonBackground: "#fad42c",
    contactConfirmationBackground: "#ffffff",
    projectButtonText: "#000000",
    projectButtonIcon: "#717171",
    contactFormBackground: "#fafafa",
    contactFormInputBackground: "#ffffff",
    contactFormInputBorder: "#cfd9de",
    contactFormLabel: "#607393",
    footerTitle: "#e6e6e6",
    modes: {
      dark: {
        text: "#e6e6e6",
        subText: "#d4d4d4",
        menuText: "whitesmoke",
        background: "black",
        primary: "blue",
        topBar: "#0e1829f5",
        menuBackground: "#162338cf",
        menuLine: "1px solid #272323",
        socialBackground: "#292929",
        scrollDownIcon: "2px solid #939393c8",
        scrollDownText: "#939393c8",
        orange: "#C84B31",
        aboutMe: "#0e131c",
        skillTab: "#565656",
        skillSpan: "#fad42c",
        skillIcon: "#ffffff60",
        projectButtonBackground: "#565656",
        contactConfirmationBackground: "#565656",
        projectButtonText: "#fad42c",
        projectButtonIcon: "#ffffff60",
        contactFormBackground: "#0e131c",
        contactFormInputBackground: "#28364f",
        contactFormInputBorder: "#cfd9de00",
        contactFormLabel: "#fad42c",
        footerTitle: "#fad42c",
      },
    },
  },
};

export default theme;
