//styles import
import "../assets/styles/Projects.css";
// component import

import ProjectEntry from "./ProjectEntry";
import Title from "./Title";

// theme-ui imports
/** @jsxImportSource theme-ui */

// image imports
import twitterImg from "../assets/images/twitter-clone.png";
import wheresWaldo from "../assets/images/whereswaldo.png";
import todoListImg from "../assets/images/todolist.png";
import weatherApp from "../assets/images/weather-app.png";
import datasetImg from "../assets/images/dataset.png";

export default function Projects() {
  return (
    <div sx={{ backgroundColor: "background" }} className="projects-container">
      <div className="projects-content">
        <Title title="PROJECTS" className="projects-title" />
        <div className="card-container">
          <ProjectEntry
            name="Barker (Twitter Clone)"
            description="Full stack Twitter clone with functional sign-in, sign-up, posting, liking, retweeting and replying to a tweet. Developed with ReactJS, React Router, and Firebase."
            img={twitterImg}
            sourceCodeUrl="https://github.com/baha-homidov/twitter-clone"
            liveDemoUrl="https://twitter-clone-a252d.firebaseapp.com/"
          />
          <div
            sx={{ backgroundColor: "scrollDownText" }}
            className="line"
          ></div>
          <ProjectEntry
            name="DataSet (C++ Project)"
            description="Set of header files that contain the implementation of the common abstract data structures. I developed this project to improve my understanding of 'Data Structures And Algorithms' and get hands-on experience by implementing the data structures in C++."
            img={datasetImg}
            sourceCodeUrl="https://github.com/baha-homidov/dataset"
            liveDemoUrl=""
            introductionButton={true}
            introductionUrl="https://firebasestorage.googleapis.com/v0/b/bahahomidov-3b2ad.appspot.com/o/DataSet.zip?alt=media&token=4b85d42f-76c3-4d29-9c0f-d410f3e79d69"
          />
          <div
            sx={{ backgroundColor: "scrollDownText" }}
            className="line"
          ></div>
          <ProjectEntry
            name="Gotta-Do List"
            description="Fully functional to-do list app with an authentication system and backend for keeping user data. It was developed without using any front-end frameworks and created with vanilla JavaScript, HTML, CSS, Firebase, and GitHub Pages."
            img={todoListImg}
            sourceCodeUrl="https://github.com/baha-homidov/to-do-list"
            liveDemoUrl="https://baha-homidov.github.io/to-do-list/"
          />
          <div
            sx={{ backgroundColor: "scrollDownText" }}
            className="line"
          ></div>
          <ProjectEntry
            name="Where's Waldo"
            description="It's a puzzle game based on the British children's puzzle books series. Developed with ReactJS, Firebase, and GitHub Pages."
            img={wheresWaldo}
            sourceCodeUrl="https://github.com/baha-homidov/where-is-waldo"
            liveDemoUrl="https://baha-homidov.github.io/where-is-waldo/"
          />
          <div
            sx={{ backgroundColor: "scrollDownText" }}
            className="line"
          ></div>
          <ProjectEntry
            name="Weather App"
            description="It's a basic weather app developed to learn how to work with APIs and asynchronous code. It was created without front-end frameworks and with vanilla JavaScript, HTML, CSS, OpenWeatherApi, and GitHub Pages."
            img={weatherApp}
            sourceCodeUrl="https://github.com/baha-homidov/weather-app"
            liveDemoUrl="https://baha-homidov.github.io/weather-app/"
          />
        </div>
      </div>
    </div>
  );
}
