// css import
import "../assets/styles/DesktopMenu.css";

// icons from react-icons
import { GoTools } from "react-icons/go";
import { TbMail } from "react-icons/tb";
import { GoPerson } from "react-icons/go";

// theme-ui imports
/** @jsxImportSource theme-ui */

export default function DesktopMenu() {


  
  return (
    <nav className="desktop-menu-container">
      <div className="button-container">
        <a href="#about-me">
          <button sx={{ color: "menuText" }} className="menu-button">
            <GoPerson />
            About Me
          </button>
        </a>
        <a href="#projects">
          <button sx={{ color: "menuText" }} className="menu-button">
            <GoTools className="icon" />
            Projects
          </button>
        </a>
        <a href="#contact">
          <button sx={{ color: "menuText" }} className="menu-button">
            {" "}
            <TbMail
              style={{ width: "25px", height: "25px" }}
              className="icon"
            />{" "}
            Contact
          </button>
        </a>
      </div>
    </nav>
  );
}
