import { useEffect, useState } from "react";
// react-spring imports
import { useTransition, animated } from "@react-spring/web";

// theme-ui imports
/** @jsxImportSource theme-ui */
import { useColorMode } from "theme-ui";
import "./App.css";
// Component import
import AboutMe from "./components/AboutMe";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import LandingScreen from "./components/LandingScreen";
import Projects from "./components/Projects";
import TopBar from "./components/TopBar";

function App() {
  const [playAnimation, setPlayAnimation] = useState(true);
  const [showContent, setShowContent] = useState(false);

  const [colorMode, setColorMode] = useColorMode();

  // helper function to toggle dark mode
  function toggleDarkMode() {
    setColorMode(colorMode === "light" ? "dark" : "light");
    console.log(colorMode === "light" ? "dark" : "light");
  }

  useEffect(() => {
    if (document.readyState === "complete") {
      setTimeout(() => {
        setPlayAnimation(false);
      }, 0);
      setTimeout(() => {
        setShowContent(true);
      }, 0);
      console.log("false");
    }

    const onPageLoad = () => {
      console.log(document.readyState);

      if (document.readyState === "complete") {
        setTimeout(() => {
          setPlayAnimation(false);
        }, 0);
        setTimeout(() => {
          setShowContent(true);
        }, 0);
        console.log("false");
      }
    };

    // Check if the page has already loaded

    window.addEventListener("load", onPageLoad);
    // Remove the event listener when component unmounts
    return () => window.removeEventListener("load", onPageLoad);
  }, []);

  // react-spring transition hook for loading
  const loadingTransition = useTransition(playAnimation, {
    from: { opacity: 1 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 0, // duration for the whole animation form start to end
    }, // TODO remove or replace the transition
  });

  return (
    <div className="app-container">
      {loadingTransition(
        (styles, playAnimation) =>
          playAnimation && (
            <animated.div
              style={{
                zIndex: 999,

                ...styles,
              }}
            >
              {
                <div className="loading" style={{ zIndex: 999 }}>
                  <section className="container">
                    <div>
                      <div>
                        <span className="one h6"></span>
                        <span className="two h3"></span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <span className="one h1"></span>
                        <span className="two h4"></span>
                      </div>
                    </div>

                    <div>
                      <div>
                        <span className="one h5"></span>
                        <span className="two h2"></span>
                      </div>
                    </div>
                  </section>
                </div>
              }
            </animated.div>
          )
      )}
      {/* sx={{ backgroundColor: "background" }} */}
      {showContent && (
        <div className="app-content">
          <TopBar colorMode={colorMode} toggleDarkMode={toggleDarkMode} />
          <section id="landing-screen">
            <LandingScreen />
          </section>
          <section id="about-me">
            <AboutMe />
          </section>
          <section id="projects">
            <Projects />
          </section>
          <section id="contact">
            <Contact />
          </section>
          <Footer />
        </div>
      )}
      {/* {contentTransition(
        (styles, showContent) =>
          showContent && (
            <animated.div style={{ zIndex: 9999, ...styles }}>
              {
                
              }
            </animated.div>
          )
      )} */}
    </div>
  );
}

export default App;
