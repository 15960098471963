// asset imports
import "../assets/styles/Footer.css";
import logoDark from "../assets/images/logo_web_dark.png";

// theme-ui imports
/** @jsxImportSource theme-ui */

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content">
        <div className="horizontal-wrapper">
          <div className="text-container">
            <img src={logoDark} className="logo" alt="" />
            {/* <div sx={{ color: "footerTitle" }} className="name">
              BAHA HOMIDOV
            </div> */}
            <div className="text">
              Building solutions that lead to the success of the products
              <div className="email">bahahomidov@outlook.com</div>
            </div>
          </div>
          <div className="button-container">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://firebasestorage.googleapis.com/v0/b/bahahomidov-3b2ad.appspot.com/o/Mirzobakhtiyor-Homidov-CV.pdf?alt=media&token=53445909-d396-43bd-9ab8-d58cfdb58028"
            >
              <button className="entry">RESUME</button>
            </a>
            <a href="#projects">
              <button className="entry">PROJECTS</button>
            </a>
            <a href="#contact">
              <button className="entry">CONTACT ME</button>
            </a>
          </div>
        </div>
        <div className="line"></div>
        <div className="rights">©2022 BAHA. ALL RIGHTS RESERVED</div>
      </div>
    </div>
  );
}
